.top-bar {
    background-color: #0070C0 !important;
    vertical-align: middle;

}

.container {
    margin: 0 !important;
    width: 100% !important;
}

.app-name {
    color : white;
    font-size: 1.2em;
    padding : 0.7% 0 0 0.5%;
}

.location {
    background-color: black;
    color : white;
    padding: 0.7% 1%;
    /* margin : 0.5% 0.5% 0.5%;} */
}

.card-header {
    color : #232899 !important;
}

.grid {
    margin : 2% !important;
}

.todays-weather-card {
    width : 90% !important;
    background-color: #FDFDFD;
}